import React from "react"
import { graphql } from "gatsby"
import Navbar from "../../components/NavBar"
import Carousel from "../../components/Carousel"
import Footer from "../../components/Footer"
import Metatags from "../../components/metatags"
import UseSiteMetadata from "../../components/UseSiteMetadata"

const MulticastEN = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "pt-BR",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | Takeno Camargo e Ramalho Advogadas"
        description="website para Takeno Camargo e Ramalho Advogadas"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <Navbar />
      <div className="h-10" />
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-3">
            <h1>Takeno Camargo e Ramalho Advogadas</h1>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-6">
            <a
              className="btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 px-3 py-1.5"
              href="https://tcradvogadas.com.br"
              target="_blank"
              rel="noopener noreferrer"
              disabled={true}
            >
              Takeno Camargo e Ramalho Advogadas
            </a>
            <div className="h-5" />
            <p>
              "Takeno Camargo e Ramalho Advogadas" é um escritório de advocacia
              com sede em São Paulo, Brasil, com experiência no âmbito cívil de
              família e sucessões.
            </p>
            <p>
              O website visa ampliar a visibilidade da empresa e inclui uma
              pequena biografia das sócias e uma decrição detalhada das áreas de
              atuação.
            </p>
            <p>
              Para a nós foi uma excelente experiência trabalhar com um design
              que não foi desenvolvido por nós.
            </p>
            <p>
              O design ficou a cargo da{" "}
              <a
                href="http://www.lailarodrigues.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Laila Rodrigues | Design gráfico e Branding</b>
              </a>.
            </p>
          </div>
        </div>
        <div className="h-8" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">desenvolvimento</div>
          <div className="col-span-4 tec-feature">gatsby js</div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">implantação</div>
          <div className="col-span-4 tec-feature">aws s3 e cloudfront</div>
        </div>
        <div className="h-10" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel
              images={data.portfolioImages.edges}
              alt="Takeno Camargo e Ramalho Advogadas"
            />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default MulticastEN

export const query = graphql`
  {
    portfolioImages: allFile(
      filter: { relativePath: { regex: "/webs/tcr/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1280)
          }
        }
      }
    }
  }
`
